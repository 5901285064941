import React, { FunctionComponent } from "react";
import { HeroCta } from "../cta/HeroCta";
import { Pattern } from "../Pattern";

import HeroTitle from "../typography/HeroTitle";
import LeadText from "../typography/LeadText";
import TitleTag from "../typography/TitleTag";

type ImageHeroProps = {
  tag: string;
  title: JSX.Element;
  description: string;
  image: string;
  imgPosition?: string;
  cta?: JSX.Element;
};

const ImageHero: FunctionComponent<ImageHeroProps> = ({
  tag,
  title,
  description,
  image,
  imgPosition = "object-right",
  cta = <HeroCta />,
}) => {
  return (
    <div className="w-full bg-gray-100 lg:h-[640px]">
      <section className="container py-12 md:py-24 lg:py-0 px-5 mx-auto h-full">
        <div className="relative lg:px-7 flex flex-wrap h-full">
          <div className="flex flex-wrap mt-auto mb-auto lg:w-7/12 xl:w-1/2 w-full content-start z-10">
            <div className="w-full mb-6">
              <TitleTag>{tag}</TitleTag>
              <HeroTitle>{title}</HeroTitle>
              <div className="mt-3 lg:mt-5 mb-6 lg:max-w-xl">
                <LeadText>{description}</LeadText>
              </div>
              {cta}
            </div>
          </div>
          <Pattern
            type="square"
            className="hidden lg:block absolute h-40 w-2/3 -bottom-16 left:0 xl:-left-20 text-gray-200 opacity-70"
          ></Pattern>
        </div>
      </section>
      <div className="hidden lg:block absolute inset-y-0 right-0 lg:w-5/12 xl:w-1/2">
        <img
          style={{
            clipPath: "polygon(10% 0px, 100% 0%, 100% 100%, 0px 100%)",
            height: 712,
          }}
          className={`h-56 w-full ${imgPosition} object-cover sm:h-72 md:h-96 lg:w-full lg:h-full`}
          src={image}
          alt="Hero image"
        />
      </div>
    </div>
  );
};

export default ImageHero;
