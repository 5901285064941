import * as React from "react";
import { graphql } from "gatsby";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { FeaturesGrid } from "../components/features-grid/FeaturesGrid";
import { FeaturesGrid3 } from "../components/features-grid/FeaturesGrid3";
import ImageCta from "../components/cta/ImageCta";
import GridBlock from "../components/grid-block/GridBlock";
import Keyword from "../components/typography/Keyword";
import { JoinHeroCta } from "../components/cta/JoinHeroCta";
import { useServiceTree } from "../hooks/useServiceTree";
import { useServiceI18nLink } from "../hooks/useI18nLink";
import TechIcon from "../components/icons/TechIcon";
import TextCta from "../components/cta/TextCta";
import { LinkCta } from "../components/typography/LinkCta";
import BodyText from "../components/typography/BodyText";
import { HeroCta } from "../components/cta/HeroCta";
import PageLayout from "../components/page-layout/PageLayout";
import SEO from "../components/SEO";
import ImageHero from "../components/hero/ImageHero";

// @ts-ignore
import heroImg from "../assets/images/hero.jpg";
import ClientsSections from "../components/sections/ClientsSection";

const IndexPage = ({ data }) => {
  const { t } = useTranslation();
  const services = useServiceTree();
  const { serviceLink } = useServiceI18nLink();

  return (
    <PageLayout headerBg="gray-100">
      <SEO
        titleI18nKey={"index:meta.title"}
        descriptionI18nKey={"index:meta.description"}
        image={heroImg}
        datePublished={new Date("2021-03-01T08:00:00Z")}
      />

      <ImageHero
        tag={t("index:hero.tag")}
        title={
          <Trans
            i18nKey="index:hero.title"
            components={{ ol: <span className="text-ol-gradient" /> }}
          />
        }
        description={t("index:hero.content")}
        image={heroImg}
      />

      {/* VALUES */}
      <FeaturesGrid
        tag={t("index:services.tag")}
        title={t("index:services.title")}
        description={<Trans i18nKey="index:services.description" />}
        elements={services.map((service) => ({
          icon: service.icon,
          name: t(`services:${service.id}.name`),
          description: t(`index:services.descriptions.${service.id}`),
          child: (
            <LinkCta
              to={serviceLink(service)}
              text={t("common:findMore")}
              align="center"
            />
          ),
        }))}
      />

      <ClientsSections theme="gray" />

      {/* CTA */}
      <ImageCta
        tag={t(`index:cta.tag`)}
        title={t(`index:cta.title`)}
        description={t(`index:cta.description`)}
        image={require("../assets/images/astrorei-cta.jpg").default}
      />

      {/* CUSTOM SOLUTIONS */}
      <GridBlock
        tag={t(`index:customSoftwareSolutions.tag`)}
        title={t(`index:customSoftwareSolutions.title`)}
        lg3={false}
        blocks={services
          .find((s) => s.id === "customSoftwareSolutions")
          .solutions.map((solution) => (
            <>
              <Keyword
                title={t(`solutions:${solution.id}.name`)}
                description={t(
                  `index:customSoftwareSolutions.descriptions.${solution.id}`
                )}
              />
              <LinkCta
                to={serviceLink(solution)}
                text={t("index:customSoftwareSolutions.ctaLink")}
                align="right"
              />
            </>
          ))}
      />

      {/* DEDICATED TEAM */}
      <FeaturesGrid3
        tag={t(`index:dedicatedDevelopmentTeam.tag`)}
        title={t(`index:dedicatedDevelopmentTeam.title`)}
        elements={services
          .find((s) => s.id === "dedicatedDevelopmentTeam")
          .solutions.map((solution) => ({
            name: t(`solutions:${solution.id}.name`),
            to: serviceLink(solution),
            icon: (
              <TechIcon
                size="xl"
                icon={solution.icon}
                className="text-gray-900 overflow-hidden"
              />
            ),
          }))}
      >
        <BodyText>{t(`index:dedicatedDevelopmentTeam.description`)}</BodyText>
        <HeroCta />
      </FeaturesGrid3>

      {/* JOIN CTA */}
      <TextCta
        tag={t(`index:joinCta.tag`)}
        title={t(`index:joinCta.title`)}
        description={<Trans i18nKey="index:joinCta.description" />}
        cta={<JoinHeroCta text={t("about:recipe.cta")} />}
      />

      {/* USE CASE SECTION */}
    </PageLayout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allServicesJson {
      edges {
        node {
          jsonId
          slug
          icon
        }
      }
    }
  }
`;
