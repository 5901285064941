import * as React from "react";
import Section from "../layout/Section";
import { useStaticQuery, graphql } from "gatsby";

interface ClientsSectionsProps {
  theme?: "white" | "gray" | "dark";
}

const ClientsSections: React.FunctionComponent<ClientsSectionsProps> = ({
  theme,
}) => {
  const clients: Record<string, {name: string, link: string, image: string}> = useStaticQuery(graphql`
    query ClientsQuery {
      bepower: clientsJson(jsonId: { eq: "bepower" }) {
        name
        link
        image
      }
      sailsquare: clientsJson(jsonId: { eq: "sailsquare" }) {
        name
        link
        image
      }
      memorabid: clientsJson(jsonId: { eq: "memorabid" }) {
        name
        link
        image
      }
      hellobarrio: clientsJson(jsonId: { eq: "hellobarrio" }) {
        name
        link
        image
      }
      limo: clientsJson(jsonId: { eq: "limo" }) {
        name
        link
        image
      }
    }
  `);

  return (
    <Section theme={theme} >
      <div className="w-full flex flex-wrap xl:flex-nowrap justify-center items-center px-6 md:px-0 lg:px-16 xl:px-0 -mb-16">
        <ClientImage {...clients.bepower} className="w-full md:w-[60%] md:ml-0 xl:w-1/3"/>
        <ClientImage {...clients.sailsquare} className="w-[45%] ml-0 md:w-[30%] md:ml-auto md:mr-0 xl:mr-auto xl:w-[14%]"/>
        <ClientImage {...clients.memorabid} className="w-[45%] mr-0 md:w-[30%] md:mr-auto md:ml-0 xl:ml-auto xl:w-[14%]"/>
        <ClientImage {...clients.hellobarrio} className="w-[60%] ml-0 md:w-[30%] md:ml-auto md:mr-auto xl:w-[14%]"/>
        <ClientImage {...clients.limo} className="w-[30%] mr-0 md:w-[15%] xl:w-[6%]"/>
      </div>
    </Section>
  );
};

const ClientImage = (props: {
  name: string;
  link: string;
  image: string;
  className: string;
}) => (<a href={props.link} target="_blank" className={
  `mb-16 m-auto md:mt-0 md:mb-16 opacity-70 hover:opacity-100 transition-opacity duration-300 ease-in-out ${props.className}`}>
  <img
    src={require(`../../assets/images/clients/${props.image}`).default}
    alt={props.name}
    className="grayscale m-auto hover:grayscale-0 transition-grayscale duration-300 ease-in-out"
  />
</a>)

export default ClientsSections;
