import React from "react";
import { TitleHeading } from "../typography/TitleHeading";
import BodyText from "../typography/BodyText";
import { HeroCta } from "./HeroCta";
import SideImage from "../layout/SideImage";
import Section from "../layout/Section";

interface TextCtaProps {
  tag: string;
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  cta?: React.ReactNode
}

const TextCta: React.FunctionComponent<TextCtaProps> = ({
  tag,
  title,
  description,
  cta = <HeroCta />
}) => {
  return (
    <Section theme="dark">
      <div className="flex flex-col max-w-4xl items-center mx-auto">
        <TitleHeading center theme="dark" tag={tag} title={title} />
        <BodyText center dark justify={false} className="mt-2" mb={8}>
          {description}
        </BodyText>
        {cta}
      </div>
    </Section>
  );
};

export default TextCta;
